<template>
  <div>
    <v-container fluid class="py-6">
      <div class="d-flex justify-between">
        <div>
          <v-icon dense class="py-2 px-6" color="orange"
            >mdi-alpha-p-circle-outline</v-icon
          >點數:
          {{ point_amount }}
        </div>

        <div>
          <v-icon dense class="py-2 px-6" color="red"
            >mdi-alpha-r-circle-outline</v-icon
          >回饋:
          {{ bonus_amount }}
        </div>

        <div class="d-flex ms-auto">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              py-2
              px-6
              me-2
            "
            color="transparent"
            small
          >
            <v-icon>ni ni-archive-2 me-1</v-icon>
            Export CSV
          </v-btn>
        </div>
      </div>

      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <table-orders
              :shop_type="shop_type"
              :history_type="history_type"
            ></table-orders>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { history_type_enum } from "@/definitions.js";
import TableOrders from "@/components/CRM/TableOrders";

export default {
  name: "Point-Use-List",
  props: {
    shop_type: {
      type: Number,
      default: 0,
    },
    history_type: {
      type: Number,
      default: history_type_enum.PointUse,
    },
  },
  data: function () {
    return {
      point_amount: 0,
      bonus_amount: 0,
    };
  },
  components: {
    TableOrders,
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    ...mapGetters(["getToken"]),
    // get points? only when init? or
    getPoints() {
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/points`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setResult(data) {
      this.point_amount = data.point_amount;
      this.bonus_amount = data.bonus_amount;
    },
  },
};
</script>
